import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Header, Presentation, Footer } from "../components";

const PresentationsStyles = styled.div`
  padding: 0 64px;
  h1 {
    text-align: center;
  }
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  @media screen and (max-width: 640px) {
    padding: 0 17px;
  }
`;

const PresentationsPage = ({ data }) => (
  <main>
    <Header />
    <PresentationsStyles>
      <h1>Zerrtech Presentations</h1>
      {data.allContentfulPresentation.nodes.map((node) => (
        <Presentation
          key={node.id}
          title={node.title}
          authors={node.authors}
          body={node.body}
        />
      ))}
    </PresentationsStyles>
    <Footer />
  </main>
);

PresentationsPage.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
};

export default PresentationsPage;

export const query = graphql`
  query {
    allContentfulPresentation(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        authors
        body {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(placeholder: BLURRED)
              file {
                contentType
                url
                fileName
              }
            }
            ... on ContentfulBlogPost {
              contentful_id
              __typename
              title
              slug
            }
          }
        }
      }
    }
  }
`;
